.aboutContainer {
  margin: 1rem auto;
}

.aboutContainer_content {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  align-items: center;
}

.imageContainer {
  display: block;
  justify-content: center;
  margin-top: 1rem;
}

.imageContainer img{
  width: 100%;
}

.samy, .thomas, .thomas-mobile{
  margin: 1rem auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
  width: 90%;
}

.samy p, .thomas p, .thomas-mobile p{
  background-image: linear-gradient(#3792cb, #3792cb), linear-gradient(#3792cb, #3792cb),
    linear-gradient(#3792cb, #3792cb), linear-gradient(#3792cb, #3792cb),
    linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  background-size: 2px 30%, 30% 2px, 2px 30%, 30% 2px,
    calc(100% - 8px) calc(100% - 8px);
  background-position: left bottom, left bottom, right top, right top, 4px 4px;
  padding: 4rem;
  text-align: justify;
}

.instagram-link {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--color-primary);
  text-decoration: none;
  font-size: 2rem;
}

.instagram-username {
  text-transform: lowercase;
  font-size: 1.2rem;
}

.about_info > h1,
p {
  margin: 0 0.5rem;
  padding: 0 1rem;
  text-align: center;
}

.about_info p {
  font-size: 1.3rem;
  margin-top: 1rem;
  
  letter-spacing: 0.1rem;
}

.thomas-mobile{
  display: none;
}

@media screen and (max-width: 1050px) {

  .about_info{
    width: 70%;
  }

    .samy, .thomas-mobile{
      display: flex;
      flex-direction: column;
      width: 60%;
    }

    .thomas{
      display: none;
    }

    .samy p, .thomas-mobile p{
      padding: 2rem 3rem;
      margin: 2rem 0;
    }

}

@media screen and (max-width: 950px) {
  .aboutContainer_content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about_info{
    margin: 2rem;
    text-align: center;
  }

  .about_info p {
    text-align: center;
  }

  .about_info > hr{
   float: none;
   margin: auto;
  }
  
}
@media screen and (max-width: 600px) {

  .about_info p, .samy p, .thomas-mobile p{
    font-size: 1.3rem;
  }

  .samy p, .thomas-mobile p{
    padding: 1.3rem 2rem;
    margin: 2rem 0;
  }
}
@media screen and (max-width: 380px) {

  .aboutContainer_content{
    max-width: 280px;
  }

  .samy p, .thomas-mobile p{
    font-size: 1rem;
    padding: 1rem 1.5rem;
    width: 240px;
  }

  .about_info{
    margin: 0;
    width: 100%;
  }


  .about_info p{
    font-size: 1rem;
    width: 240px;
    padding: 0;
    
  }

  .about_info > hr{
    width: 150px;
  }

  .imageContainer img{
      width: 230px;
  }

 .instagram-link{
  gap: 8px;
 }

 .instagram-username{
  font-size: 1rem;
 }
  
}
