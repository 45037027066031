.hero__container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent;
 
}

.hero__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background-image: url("../../assets/Images/bg-hero.jpg");*/
    background-size: cover;
    background-position: center;
    opacity: 0.6;
    z-index: -1;
}

.hero__container-content {
    text-align: center;
    margin-bottom: 5rem; 
}

.hero__container-content img {
    max-width: 100%;
    height: auto;
}

.hero__container-content p:nth-child(2){
    font-size: 3.5rem;
}
.hero__container-content p:nth-child(4){
    font-size: 2.2rem;
}
.hero__container-content hr{
    width: 5rem;
}
