@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@400;700&display=swap');

* {
  box-sizing: border-box;
  font-family: "Mulish", serif;
  padding: 0;
  margin: 0;
  scroll-behavior:  smooth;
}

:root{
  --color-bg: #070504;
  --color-primary: #3792cb;
  --color-primary-variant: rgba(55, 146, 203, 0.6);
  --color-secondary:#fff;

  --transition:all 400ms ease;
}

body {
  background: var(--color-bg);
  
}

h1,h2,h3,h4,h5{
  color: var(--color-primary);
  font-family: "Bebas Neue", sans-serif;
  letter-spacing: 0.1rem;
}

h1{
  font-size: 4rem;
}

h3{
  font-size: 1.5rem;
}

p{
  font-size: 1.5rem;
}



p,small,ul,li,a,span, button{
  font-family: "Teko", sans-serif;
}

p, small{
  color: var(--color-secondary);

}

ul,li,
a {
  color: var(--color-primary);
  text-decoration: none;
  list-style: none;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.2rem;
  cursor: pointer;
}

hr{
  width: 600px;
  margin: auto;
  border:1px solid var(--color-primary);
}

button {
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 1.2rem;
  margin-top: 1rem;
  cursor: pointer;
  padding: 1rem 2rem;
  background-color: var(--color-primary);
  color: var(--color-secondary);
  border: 1px solid var(--color-primary);
}

button:hover{
  background-color: transparent;
  transition: 1.5s all;
}

.return-btn {
  display: flex;
  padding: 0.5rem 1rem;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 1.5rem;
  color: var(--color-secondary);
  background-color: var(--color-primary);
  border: 1px solid var(--color-secondary);
  width: 200px;
}

.return-btn:hover{
  background-color: transparent;
  transition: 1.5s all;
  border: 1px solid var(--color-primary);
}

.app__navbar{
  background-color: transparent;
}

.app__navbar.colorChange{
  background-color: #000;
  border-bottom: 1px solid var(--color-primary);
}

.flex__center {
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section__padding{
  padding-top: 100px;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}


@media screen and (max-width:650px){
  hr{
    width: 200px;
  }
}