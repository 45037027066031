
.contact__container{
    min-height: 100vh;
    height: 700px;
    margin-bottom: 100px;
}



.contact__container-content{
    margin:  auto;
    width: 50%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 12%;
}

/* FORM */

.contact__container-content h1, p{
    text-align: center;
}

.contact__container-content p{
    letter-spacing: 0.1rem;
}

.contact__container-content button{
    width: 250px;
    margin: 2rem auto;
}

form{
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

input, textarea {
    width: 100%;
    letter-spacing: 0.2rem;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 1px solid var(--color-primary);
    resize: none;
    color: var(--color-secondary);
}



@media screen and (max-width: 1300px){
    .contact__container-content p{
        font-size: 1.1rem;
    }
    .contact__container-content button{
        margin-top: 0;
        padding: 0.5rem 2rem;
    }

    input, textarea{
        padding: 0.8rem;
    }

    .contact__container-content{
        gap: 4%;
    }
}
@media screen and (max-width: 1050px){
    .contact__container-content {
        width: 70%;
    }
}



/* MEDIA QUERIES (SMALL DEVICES) */ 

@media screen and (max-width: 800px){
    .contact__container-content{
        width: 80%;
    }
    .contact__container-content button{
        margin-bottom: 5rem;
    }
}


@media screen and (max-width: 280px){
    .contact__container{
        width: 170px;
        padding: 0rem;
    }

    
}