footer{
    background: black;
    border-top: 1px solid var(--color-primary);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}


.footer__logo{
    font-size: 2rem;
    font-weight: 500;
    
    display: inline-block;
}

.footer__logo img{
    width:400px;
    height: 400px;

}

.permalinks{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}

.permalinks a{
    font-size: 1.2rem;
}

.permalinks a:hover{
    color: var(--color-secondary);
    transition: 2s all;
}



.footer__socials{
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
}

.footer__socials a{
    background: var(--color-primary);
    color: var(--color-secondary);
    padding: 0.8rem;
    border-radius: 0.8rem;
    display: flex;
    border:1px solid transparent;
    
}

.footer__socials a:hover{
    background: transparent;
    border-color: var(--color-primary);
    transform: scale(1.5);
    transition: 2s all;
}

.footer__legal{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.footer__copyright{
    margin-bottom: 4rem;
    font-size: 1.5rem;
}

/* MEDIA QUERIES (SMALL DEVICES) */ 

@media screen and (max-width: 600px){
    .permalinks{
        flex-direction: column;
        gap: 1.5rem;
    }

    .footer__socials{
        margin-bottom: 2.6rem;
    }
}


@media screen and (max-width: 280px){
    footer{
        width: 100%;
    }
}