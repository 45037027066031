.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 5px solid #ccc;
  border-top-color: #666;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


.app__navbar {
    position:sticky;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 20rem;
  }

  .app__navbar-link .active {
    background-color: white;
  }
  
  .app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .app__navbar-logo img {
    max-width: 100px; /* Réglez la largeur maximale du logo selon vos préférences */
    height: auto;
    display: block; /* Cette propriété garantit que les marges automatiques fonctionnent */
    margin: 0 auto; /* Centre le logo horizontalement */
  }
  
  .app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: right;
    align-items: center;
  
    list-style: none;
  }
  
  .app__navbar-links li {
    margin: 0 1rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    padding: 0.5rem 1rem;
  }

  .app__navbar-links li a{
    letter-spacing: 0.2rem;
    font-size: 1.2rem;
  }

  .app__navbar-links li:hover{
    transform: scale(1.1);
    transition: all 1s;
    color: var(--color-primary-variant);
  }
  
  .app__navbar-login {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .app__navbar-login a {
    margin: 0 1rem;
    text-decoration: none;
    transition: 0.5s ease;
  }
  
  .app__navbar-login a:hover {
    border-bottom: 1px solid gold;
  }
  
  .app__navbar-login div {
    width: 1px;
    height: 30px;
    background-color: grey;
  }
  
  .app__navbar-smallscreen {
    display: none;
  }
  
  .app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: black;
    transition: 0.5s ease;
    flex-direction: column;
    z-index: 5;
  }
  
  .app__navbar-smallscreen_overlay .overlay_close {
    font-size: 27px;
    color: var(--color-primary);
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  .app__navbar-smallscreen-links {
    list-style: none;
    margin-top: 6rem;
  }
  
  .app__navbar-smallscreen-links li {
    margin: 2rem;
    cursor: pointer;
    color: white;
    font-size: 2rem;
    text-align: center;
  
  }
  
  .app__navbar-smallscreen-links li:hover {
    color: var(--color-white);
  }
  
  .modal_login{
    font-size: 12px;
    background: black;
    width: 400px;
    height: 350px;
    border: 1px solid white;
    border-radius: 3px;
  }

  .app__navbar-login-mobile{
    color: white;
    text-align: center;
    margin-top: 6rem;
  }
  .modal_login > .header{
    width: 100%;
    font-size: 25px;
    text-align: center;
    padding: 5px;
    color: white;
  }
  .modal_login > .content{
    width: 100%;
    padding: 45px 10px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 20px;
  }
  
  .a_register {
    color: white;
    padding-top: 1rem;
    font-size: 0.8rem;
    font-weight: bold;
    cursor: pointer;
  }
  
  .input_login {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid white;
    background: transparent;
    color: white;
  }
  
  .modal_login > .close {
    cursor: pointer;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    color: white;
    background: black;
  }


  .language__container-btn{
    margin: 0.5rem;
    padding: 0.5rem 0.8rem;
    border-radius: 50%;
    background: none;
    border: none;
  }


  .language__container-mobile{
    width: 100px;
    margin: auto;
  }
  .language__container-btn-mobile{
    margin: 0.1rem;
    padding: 0.5rem 0.8rem;
    background: none;
    border: none;
  }

  
  @media screen and (max-width: 2000px) {
    .app__navbar-logo img {
      width: 210px;
    }
  }

  @media screen and (max-width:1650px) {
    .app__navbar {
      padding: 1rem 10rem;
    }
  }
  @media screen and (max-width:1350px) {
    .app__navbar {
      padding: 1rem 7rem;
    }
  }
  
  @media screen and (max-width: 1150px) {
    .app__navbar-links, .language__container{
      display: none;
    }
  
    .app__navbar-smallscreen {
      display: flex;
    }

    .app__navbar-login {
        display: none;
      }
  }
  
  @media screen and (max-width: 650px) {
    .app__navbar {
      padding: 1rem;
    }
  
    .app__navbar-login {
      display: none;
    }
  
    .app__navbar-logo img {
      width: 110px;
    }
  }