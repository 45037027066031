.localisation__container{
    min-height: 80vh;
}

.localisation__container-content h1{
    text-align: center;
    margin-bottom: 5rem;
}

.localisation__grid {
    display: grid;
    grid-template-columns: 1fr 3fr; /* Deux colonnes égales */
    gap: 20px; /* Espace entre les colonnes */
  }
  
  .localisation__column {
    padding: 10px;
  }

  .localisation__column span{
    font-size: 2rem;
  }  
  .localisation__column p {
    font-size: 1.5rem;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 768px) {
    .localisation__grid {
      grid-template-columns: 1fr; /* Une seule colonne */
    }
    
    .localisation__column {
      padding: 10px;
    }

    .localisation__column p {
      text-align: center;
    }
  }
  
  /* Écran petit (smartphone) */
  @media screen and (max-width: 480px) {
    
    
    .localisation__grid {
      grid-template-columns: 1fr; /* Une seule colonne */
      gap: 10px; /* Réduisez l'espace entre les colonnes */
    }
    
    .localisation__column {
      padding: 10px;
    }
    
    
    .localisation__column p {
      font-size: 1.5rem; /* Ajustez la taille de la police */
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }