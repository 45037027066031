.booking__container {
    min-height: 80vh;
    margin-bottom: 100px;
    
    
  }
  
  .booking__container-content {
    text-align: center;
    border: 1px solid var(--color-primary);
    border-radius: 1rem;
    padding: 4rem;
    
  }
  
  .booking__container-content h1 {
    margin: 2rem;
  }
  
  .booking__container-content > p {
    margin-bottom: 2rem;
  }
  
  .schedule__container {
    height: 90vh;
    width: 80vw;
  }
  
  .schedule > .close {
    color: var(--color-primary);
    background: white;
    border: 1px solid var(--color-primary);
    border-radius: 50%;
    display: flex;
    text-align: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 4px 4px 4px 6px;
    line-height: 26px;
    font-size: 2rem;
    position: absolute;
    left: -2.8rem;
    top: -1.5rem;
  }
  
  .schedule__links button {
    position: absolute;
    margin-bottom: 7rem;
  }
  
  .schedule__button {
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-size: 1.4rem;
    cursor: pointer;
    padding: 1rem 2rem;
    background-color: var(--color-primary);
    color: var(--color-secondary);
    border: 1px solid var(--color-primary);
  }
  
  .schedule__button:hover,
  .btn-mobile:hover,
  .schedule__links button:hover {
    background-color: black;
    transition: 1.5s all;
  }
  
  @media screen and (max-width: 1024px) {
    .img-desktop {
      margin: 0 auto;
    }
  }
  
  @media screen and (max-width: 600px) {
    .booking__container-content {
      width: 400px;
    }
  }
  @media screen and (max-width: 450px) {
    .booking__container-content {
      width: 300px;
    }
  
    .booking__container-content h1 {
      font-size: 2.2rem;
      margin: 0;
    }
  
    .booking__container-content button {
      font-size: 1.4rem;
      margin: 2rem auto auto auto;
      padding: 1rem;
    }
  
    .booking__container-content > p {
      font-size: 1.5rem;
    }
  }
  @media screen and (max-width: 280px) {
    .booking__container-content {
      padding: 3rem 1.5rem;
      width: 250px;
    }
  }
  