.membershipContainer {
  flex-direction: column;
  margin-top: 4rem;
}

.membershipContainer h1{
  margin-bottom: 1rem;
}

.membershipContainer-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 6rem 0;
  gap: 3rem;
  align-items: center;
}

.membershipContainer li {
  background-color: black;
  list-style: none;
  padding: 2rem;
  border: 1px solid var(--color-primary);
  border-radius: 1rem;
  text-align: center;
  height: 300px;
}

.membershipContainer a{
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 1rem 2rem;
  background-color: var(--color-primary);
  color: var(--color-secondary);
  border: 1px solid var(--color-primary);
}

.membershipContainer-content a:hover{
  background-color: transparent;
  transition: 1.5s all;
}

.membershipContainer > h3,
p,
button {
  margin: 2rem;
}

.membershipContainer h3 {
  margin-bottom: 2rem;
}

.membershipContainer p {
  margin-bottom: 5rem;
}

.membershipContainer li > button {
  width: max-content;
  display: inline-block;
}

@media screen and (max-width: 1300px) {


  .membershipContainer {
    gap: 1rem;
    margin: 3rem 0;
  }

  .membershipContainer li {
    padding: 2rem 1rem;
  }
}

@media screen and (max-width: 1050px) {
  .membershipContainer-content {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .informations {
    text-align: center;
  }
}
@media screen and (max-width: 350px) {
  .membershipContainer h1 {
    font-size: 3rem;
  }
}
