.terms__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    margin: 2rem 5rem;
}

.terms__header{
    margin: 2rem auto;
}

.terms__content{
    margin: auto 10rem;
}

.terms__content p{
    text-align: justify;
    padding: 2rem;
    margin-bottom: 2rem;
    border-left: 1px solid var(--color-primary);
    border-right: 1px solid var(--color-primary);
}


@media screen and (max-width:1050px) {
    .terms__content{
        margin: auto 5rem;  
    }

    .terms__header h1{
        font-size: 3rem;
    }
}

@media screen and (max-width:750px) {
    .terms__content{
        margin: auto 2rem;
        
    }

    .terms__header h1{
        font-size: 2rem;
    }
}

@media screen and (max-width:600px) {

    .terms__container{
        margin: 1rem 3rem;
    }

    .terms__content{
        margin: 0 ;
        
    }

    .terms__header h1{
        text-align: center;
    }

    .terms__content p{
        font-size: 1.25rem;
        padding: 1rem;
    }
}
