.container.testimonials__container{
    width: 40%;
}

.testimonial_header{
    text-align: center;
    margin-bottom: 4rem;
}



.client__avatar{
    width: 4rem;
    height:4rem;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.2rem solid var(--color-primary-variant);
    
}

.company img{
    display: none;
}

.client__avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Assurez-vous que l'image couvre entièrement le conteneur */
  }

.testimonial {
    background: var(--color-white);
    border: 2px solid var(--color-primary-variant);
    text-align: center;
    padding: 1rem;
    border-radius: 2rem;
    user-select: none;
}

.client__review{
    color: var(--color-secondary);
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 1.6px;
    display: block;
    width: 80%;
    margin: 0.8rem auto 1rem;
}

/* MEDIA QUERIES (MEDIUM DEVICES) */ 

@media screen and (max-width: 1024px){
    .container.testimonials__container{
        width: 60%;
    }
}

/* MEDIA QUERIES (SMALL DEVICES) */ 

@media screen and (max-width: 600px){
    .container.testimonials__container{
        width: var(--container-width-sm);
    }

    .client__review{
        width: var(--container-width-sm);
    }
}

@media screen and (max-width: 280px){
    .container.testimonials__container{
        width: 110%;
        padding: 0rem 1rem;
    }
}